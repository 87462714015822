/**
 * @param baseUrl MUST end with /
 */
export function MakeUrl(baseUrl: string, path: string): string {
    path = path.trim()
    if (path[0] === '/') {
        path = path.substr(1)
    }
    return baseUrl + path
}

export function Host(path: string): string
export function Host(path: undefined): undefined
export function Host(path: string | undefined): string | undefined {
    if (typeof path === 'string') {
        return MakeUrl(process.env.REACT_APP_HOST, path)
    } else {
        return undefined
    }
}

export function MyFelfelHost(path: string): string
export function MyFelfelHost(path: undefined): undefined
export function MyFelfelHost(path: string | undefined): string | undefined {
    if (typeof path === 'string') {
        return MakeUrl(process.env.REACT_APP_MY_FELFEL_HOST, path)
    } else {
        return undefined
    }
}